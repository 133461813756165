import { useEffect } from 'react';
import type { BulmaMode } from '../../react/bulma/typings/bulma.js';
import type { GlobalStoreStateSelector, StoreDef } from '../../zustand/index.js';
import { BULMA } from '../../react/bulma/constants.js';
import { useActionCallback } from '../../zustand/index.js';

export type WindowState = {
  readonly width: number;
  readonly height: number;
  readonly bulmaMode: BulmaMode;
};

export type WindowStateContainer = {
  readonly window: WindowState;
};

export type WindowStateStoreDef = StoreDef<WindowStateContainer, object>;

export function useWindowSizeTracker<SD extends WindowStateStoreDef>(
  $gs: GlobalStoreStateSelector<SD>
) {
  const eventListener = useActionCallback(
    function windowResizeHandlerAction({ actionDispatch }) {
      // Execute the code within an action in order to generate one only state change
      // (or even none if both width & height are not modified)
      const { innerHeight, innerWidth } = window;
      actionDispatch.setProperty('height', innerHeight);
      actionDispatch.setProperty('width', innerWidth);
      if (innerWidth < BULMA.TABLET_MODE_LOWER_WIDTH) {
        actionDispatch.setProperty('bulmaMode', 'Mobile');
      } else if (innerWidth < BULMA.DESKTOP_MODE_LOWER_WIDTH) {
        actionDispatch.setProperty('bulmaMode', 'Tablet');
      } else if (innerWidth < BULMA.WIDESCREEN_MODE_LOWER_WIDTH) {
        actionDispatch.setProperty('bulmaMode', 'Desktop');
      } else if (innerWidth < BULMA.FULLHD_MODE_LOWER_WIDTH) {
        actionDispatch.setProperty('bulmaMode', 'Widescreen');
      } else {
        actionDispatch.setProperty('bulmaMode', 'FullHD');
      }
    },
    [],
    $gs.$window
  );

  useEffect(
    function registerWindowSizeTracker(): () => void {
      // Register a click listener
      // JS event loop handle listeners like asynchronous notifications. The signature of the callback is
      // () => void but it can handle async callbacks
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      window.addEventListener('resize', eventListener, false);
      // Call one to initialize with the initial size
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      eventListener();
      return (): void => {
        // Unregister the click listener
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        window.removeEventListener('resize', eventListener, false);
      };
    },
    [eventListener]
  );
}
