import { EMPTY_IMAGE_MODAL_STATE } from '@stimcar/libs-uitoolkit';
import type { StoreState } from './typings/store.js';
import {
  EMPTY_CONFIRM_REMOVE_DEVICE_DIALOG_STATE,
  EMPTY_DEVICE_DETAILS_STATE,
  EMPTY_EDIT_DEVICE_DIALOG_STATE,
} from './typings/store.js';

// Empty state
export const EMPTY_STORE_STATE: StoreState = {
  message: false,
  imageModal: EMPTY_IMAGE_MODAL_STATE,
  devices: [],
  editDeviceDialog: EMPTY_EDIT_DEVICE_DIALOG_STATE,
  deviceDetails: EMPTY_DEVICE_DETAILS_STATE,
  confirmRemoveDeviceDialog: EMPTY_CONFIRM_REMOVE_DEVICE_DIALOG_STATE,
  locationFilter: '',
  window: {
    width: 0,
    height: 0,
    bulmaMode: 'Mobile',
  },
  bulmaTheme: 'light',
};
